//Appbar detection
export const BARCHANGE = 'BARCHANGE';
export const BILL_PAYMENT = 'BILL_PAYMENT';
export const BILL_PAYMENT_SUMMARY = 'BILL_PAYMENT_SUMMARY';
export const BILL_BILL_SUMMARY = 'BILL_PAYMENT_SUMMARY';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH = 'REFRESH';
export const USER = 'USER';
export const COLLECTION_PAYMENT = 'COLLECTION_PAYMENT';
export const COLLECTION_PAYMENT_RFID = 'COLLECTION_PAYMENT_RFID';
export const COLLECTION_PAYMENT_DASHBOARD = 'COLLECTION_PAYMENT_DASHBOARD';
export const CONNECT7 = 'CONNECT7';
export const CONNECT7_UPDATE = 'CONNECT7_UPDATE';
export const CONNECT7_REPORT = 'CONNECT7_REPORT';
export const CONNECT7_UPLOAD = 'CONNECT7_UPLOAD';
export const DISBURSEMENT_INQUIRY = 'DISBURSEMENT_INQUIRY';
export const DISBURSEMENT_COMPANY = 'DISBURSEMENT_COMPANY';
export const DISBURSEMENT_BALANCE = 'DISBURSEMENT_BALANCE';