import jwtDecode from 'jwt-decode'
import history from './../services/history';
const errPath = 'checkAuth'
const checkToken = () => {
    try {
        const token = JSON.parse(localStorage.getItem("access_token"));
        if(token){
            if(jwtDecode(token).iss !== process.env.REACT_APP_ISSUER && jwtDecode(token).azp !== process.env.REACT_APP_PART){
                localStorage.clear();
                return false
            }
            if(jwtDecode(token).exp <  Date.now() / 1000){
                localStorage.clear();
                
                return false
            }
            setup(jwtDecode(token));
            return true
        }
        localStorage.clear();
        return false
    } catch (error) {
        localStorage.clear();
        return false
    }
    
}
const setup = (decoded) => {
    setUser(decoded)
}
const setUser = (decoded) => {
    if(decoded.name){
        localStorage.setItem('fullname', decoded.name.toUpperCase())
    }
}
const checkAccount = () => {
    try {
        const check = checkToken()
        if(check){
            const token = JSON.parse(localStorage.getItem("access_token"));
            return jwtDecode(token).user_account === process.env.REACT_APP_ACC
        }

        history.push('signin');
    } catch (error) {
        localStorage.clear();
    }
    
}

const checkAuth = {
    checkToken,
    checkAccount
}

export default checkAuth