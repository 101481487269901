import { CONNECT7,CONNECT7_UPDATE, CONNECT7_REPORT, CONNECT7_UPLOAD } from '../actions/types';

const initialState = [];

function connect7Rds (creds = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CONNECT7 : 
            return [...creds , payload];
        case CONNECT7_UPDATE : 
            return [...creds , payload];
        case CONNECT7_REPORT : 
            return [...creds , payload];
        case CONNECT7_UPLOAD : 
            return [...creds , payload];
        default :
            return creds;
    }
}

export default connect7Rds;