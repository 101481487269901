import React , { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom'
import Route from './Routes';

// const SignIn = lazy(() => import('../modules/authentication/SignIn'));
const Dashboard = lazy(() => import('./../modules/dashboard/DashboardNew')); //import('./../modules/dashboard/Dashboard'));
const Bills = lazy(() => import('./../modules/billers/payment/bills/Bills'));
const TransHistory = lazy(() => import('./../modules/billers/payment/history/TransHistory'));
const Login = lazy(() => import('./../modules/authentication/Login'));//Login_old
const ForgotPassword = lazy(() => import('./../modules/authentication/ForgotPassword'));
const MechantBills = lazy(() => import('./../modules/merchants/collection/bills/Mbill'));
// const Disbursement = lazy(() => import('./../modules/merchants/collection/disbursement/Disbursement'));
const MerchantTransHist = lazy(() => import('./../modules/merchants/collection/history/MtransHistory'));
const BillsHist = lazy(() => import('./../modules/merchants/collection/bills/components/BillsHistoryNew'));
const DisbHist = lazy(() => import('./../modules/merchants/collection/disbursement/components/DisbHistory'));
const Realtime = lazy(() => import('./../modules/realtime'));
const BPPage711 = lazy(() => import('./../modules/7connect/bp_page/7_11'));
const BillPay711 = lazy(() => import('./../modules/7connect/payment/bill_payments'));
const RepTrans711 = lazy(() => import('./../modules/7connect/payment/reported_transactions'));
const Plist = lazy(() => import('../modules/reports/rfid/prooflist/ProofList'));
const NewBill = lazy(() => import('../modules/payments/biller'));
const NewBillHist = lazy(() => import('../modules/payments/biller/components/history'));
const MDisbursement = lazy(() => import('../modules/disbursement/components/view/MDisbursement'));
const MHistory = lazy(() => import('../modules/disbursement/components/view/MHistory'));


export default function Routes() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route path="/signin" component={Login} componentName='signin' />
                <Route path="/forgotpass" component={ForgotPassword}/>
                
                <Route path="/" exact component={Dashboard} isPrivate accessRole='user'/>
                <Route path="/dashboard" component={Dashboard} isPrivate accessRole='user'/>
                <Route path="/bills" component={Bills} isPrivate accessRole='biller_bill'/>
                <Route path="/transHistory" component={TransHistory} isPrivate accessRole='biller_hist'/>
                <Route path="/mbills" component={MechantBills} isPrivate accessRole='merchant_bill'/>
                {/* <Route path="/disbursement" component={Disbursement} isPrivate accessRole='x'/> */}
                <Route path="/mtransHist" component={MerchantTransHist} isPrivate accessRole='x'/>
                <Route path="/billsHist" component={BillsHist} isPrivate accessRole='merchant_hist'/>
                {/* <Route path="/disbHist" component={DisbHist} isPrivate accessRole='x'/> */}
                <Route path="/realtime" component={Realtime} isPrivate />
                <Route path="/bp711" component={BPPage711} isPrivate accessRole='7connect_eleven'/>
                <Route path="/billPay711" component={BillPay711} isPrivate accessRole='7connect_biller_bill'/>
                <Route path="/reportTranx" component={RepTrans711} isPrivate accessRole='7connect_rep_trans'/>
                <Route path="/plist" component={Plist} isPrivate accessRole='rfid_reports'/>
                <Route path="/newBills" component={NewBill} isPrivate accessRole='biller_bill'/>
                <Route path="/newTransHistory" component={NewBillHist} isPrivate accessRole='biller_hist'/>
                <Route path="/disbursement" component={MDisbursement} isPrivate accessRole='disbursement'/>
                <Route path="/disbhist" component={MHistory} isPrivate accessRole='disbursehist'/>
                
                {/* <Route component={PageNotFound} /> */}
            </Switch>
        </Suspense>
    );
}