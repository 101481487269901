// import { useKeycloak } from '@react-keycloak/web';
import jwtDecode from 'jwt-decode'
const hasRole = (role) => {
    try {
        const token = JSON.parse(localStorage.getItem("access_token"));
        const valroles = jwtDecode(token).realm_access.roles
        return valroles.some(vrole => {
            
            if(vrole === role) {
                return true
            }

            return false
        })
    } catch (error) {
        return false
    }
    
}
const authByRoles = (roles) => {
    // const {keycloak} = useKeycloak();

    const isAutherized = () => {
        if (roles) {
            return roles.some(r => {
                const realm =  hasRole(r);
                return realm;
            });
        }
        return false;
    }
    return isAutherized();
}

const authByAttribute = (keycloak,attr) => {
    // const {keycloak} = useKeycloak();

    const isAuth = () => {
        if(keycloak && attr) {
            keycloak.loadUserInfo().then(
                user => {
                    
                    return attr.some(a => {
                        return user.user_account === a ? true : false
                    })
                    
                }
            )
            .catch(
                err => {
                    console.log(err)
                    return false
                }
            )
        }
        return false
    }
    return isAuth()
}

const authISU = () => {
    const isAutherized = () => {
        const token = JSON.parse(localStorage.getItem("access_token"));
        const roles = jwtDecode(token).realm_access.roles
        return roles.some(role => {
            if(role === process.env.REACT_APP_ISU) {
                return true
            }

            return false
        })

    }
    return isAutherized();
}
const authISUA = () => {
    const isAutherized = () => {
        const token = JSON.parse(localStorage.getItem("access_token"));
        const roles = jwtDecode(token).realm_access.roles
        return roles.some(role => {
            if(role === process.env.REACT_APP_ISUA) {
                return true
            }

            return false
        })

    }
    return isAutherized();
}

const authAUTO = () => {
    const isAutherized = () => {
        const token = JSON.parse(localStorage.getItem("access_token"));
        return jwtDecode(token).auto_load ? jwtDecode(token).auto_load : false

    }
    return isAutherized();
}

const AuthorizedFunction = {
    authByRoles,
    authByAttribute,
    authISU,
    authAUTO,
    authISUA
}
export default AuthorizedFunction