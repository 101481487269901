import { combineReducers } from "redux";

// import signinRds from "./signinRds";
// import detectRds from './detectRds';
import appbarRds from './appbarRds';
import billpaymentRds from "./billpaymentRds";
import authenticationRds from "./authenticationRds";
import profileRds from './profileRds';
import collectPaymentRds from "./collectPaymentRds";
import connect7Rds from "./connect7Rds";

export default combineReducers({
    // signinRds,
    // detectRds,
    appbarRds,
    billpaymentRds,
    authenticationRds,
    profileRds,
    collectPaymentRds,
    connect7Rds
})