import { BARCHANGE } from './../actions/types';

const initialState = [];

function barchangeReducer(bar = initialState, action) {
    const {type, payload } = action;

    switch(type) {
        case BARCHANGE : 
            return [...bar, payload];
        default :
            return bar;
    }
}

export default barchangeReducer;