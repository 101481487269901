import { COLLECTION_PAYMENT,COLLECTION_PAYMENT_DASHBOARD, COLLECTION_PAYMENT_RFID } from './../actions/types'

const initialState = [];

function collectPaymentRds (creds = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case COLLECTION_PAYMENT : 
            return [...creds , payload];
        case COLLECTION_PAYMENT_DASHBOARD : 
            return [...creds , payload];
        case COLLECTION_PAYMENT_RFID : 
            return [...creds , payload];
        default :
            return creds;
    }
}

export default collectPaymentRds;