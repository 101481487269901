import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'
import checkAuth from './../util/checkAuth';
import jwtDecode from 'jwt-decode';
import AuthorizedFunction from '../util/AuthorizedFunction';

export default function RouterWrapper ({
    component : Component,
    isPrivate,
    componentName,
    accessRole,
    ...rest
}) {
    /*will use redux for signed variable to make it dynamic*/
    const { keycloak } = useKeycloak();
    
    const signed = keycloak.authenticated;

    /**defaultLandPage is only use because dashboard is still under maintenance */
    let defaultLandPage = 'dashboard'
    
    if(signed){
        localStorage.setItem('access_token', JSON.stringify(keycloak.token))
        localStorage.setItem('user', JSON.stringify(keycloak.tokenParsed))
        checkAuth.checkToken();
        //remove when dashboard maintenance is done
        try {
            const token = JSON.parse(localStorage.getItem("access_token"));
            if(jwtDecode(token).belongs_to === 'billers'){
                defaultLandPage = 'bills'
            }
            if(jwtDecode(token).belongs_to === 'merchants'){
                defaultLandPage = 'mBills'
            }
            if(jwtDecode(token).belongs_to === '7connect'){
                defaultLandPage = 'billPay711'
            }
        } catch (error) {
            console.log('router error', error);
        }

        if(accessRole){
            if(!AuthorizedFunction.authByRoles([accessRole])){
                console.log('Not Accessible')
                return <Redirect to={"/"+defaultLandPage} />
            }
        }
        
        
    }

    

    if(componentName === 'signin' && signed) {
        return <Redirect to={"/"+defaultLandPage} />
    }

    if(isPrivate && !signed) {
        return <Redirect to="/signin" />
    }

    if(isPrivate && !signed) {
        return <Redirect to={"/"+defaultLandPage} />
    }

    return <Route {...rest} component = {Component}/>

}

RouterWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    // component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

RouterWrapper.defaultProps= {
    isPrivate: false,
}