import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducers from '../reducers'; 

import { checkTokenExpMiddleWare } from './../middlewares/tokenCheck';
const middleWares = [thunk, checkTokenExpMiddleWare];
// const middleWares = [thunk];
const initialState = {};

const configureStore = () => {
    return createStore (
        rootReducers,
        initialState,         
        compose(applyMiddleware(...middleWares))
    );
};

export default configureStore;