import { LOGIN,LOGOUT, REFRESH } from './../actions/types'

const initialState = [];

function authenticationRds (creds = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOGIN : 
            return [...creds , payload];
        case REFRESH : 
            return [...creds , payload];
        case LOGOUT : 
            return [...creds , payload];
        default :
            return creds;
    }
}

export default authenticationRds;