import { BILL_PAYMENT, BILL_PAYMENT_SUMMARY } from './../actions/types'

const initialState = [];

function billpaymentRds (creds = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case BILL_PAYMENT : 
            return [...creds , payload];
        case BILL_PAYMENT_SUMMARY : 
            return [...creds , payload];
        default :
            return creds;
    }
}

export default billpaymentRds;