import jwtDecode from 'jwt-decode'

export const checkTokenExpMiddleWare = store => next => action => {
    try {
        const token = JSON.parse(localStorage.getItem("access_token"));
        if(token){
            next(action)
            if(jwtDecode(token).iss !== process.env.REACT_APP_ISSUER && jwtDecode(token).azp !== process.env.REACT_APP_PART){
                next(action);
                localStorage.clear();
            }
            if(jwtDecode(token).exp <  Date.now() / 1000){
                next(action);
                localStorage.clear();
            }
    
        }
        
    } catch (error) {
        next(action)
        localStorage.clear();
    }
    
    next(action);
}