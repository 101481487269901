// import logo from './logo.svg';
import './css/App.css';
import { Router } from 'react-router-dom'
import Routes from './routes';
import history from './services/history';
import keycloak from './services/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import CircularProgress from '@material-ui/core/CircularProgress';

// login-required
// check-sso
const keycloakProviderInitConfig = {
  onload: 'login-required',
}

function App() {
  return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakProviderInitConfig}
        LoadingComponent={
          <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <CircularProgress size="large" tip="Loading..." />
          </div>
        }
        // onEvent={(event, error) => {
        //   console.log('onKeycloakEvent', event, error);
        // }}
        // onTokens={(tokens) => {
        //   console.log('onKeycloakTokens', tokens);
        // }}
      >
        <Router history={history}>
            <Routes />
        </Router>
      </ReactKeycloakProvider>
  );
}

// export default connect()(App);
export default App;
