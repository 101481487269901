import { USER } from './../actions/types'

const initialState = [];

function authenticationRds (creds = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case USER : 
            return [...creds , payload];
        default :
            return creds;
    }
}

export default authenticationRds;